@font-face {font-family: "Suisse Intl";
    src: url("./fonts/88f10bf18a36407ef36bf30bc25a3618.eot"); /* IE9*/
    src: url("./fonts/88f10bf18a36407ef36bf30bc25a3618.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.woff2") format("woff2"), /* chrome firefox */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.woff") format("woff"), /* chrome firefox */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

body {
    /* font-family: "Suisse Intl", sans-serif; */
}

.single-image:hover a img {
    transform: none;
}

.header-right-wrap .same-style.header-search .search-content form .button-search,
.scroll-top {
    background: #000000;
    border-color: #000000;
    color: #ffffff;
}

a:hover {
    color: #000000;
}

.btn-hover a::after, .btn-hover button::after {
    background: #000000;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
    background-color: #000000;
    color: #fff;
}

.product-wrap .product-img .product-action > div, .product-list-image-wrap .product-img .product-action > div {
    background-color: #3a434a;
}

.shop-top-bar .shop-tab a.active, .shop-top-bar .shop-tab button.active {
    color: #000;
}

.product-details-content .pro-details-quality .pro-details-cart button.bg-none:hover {
    color: #fff;
	background-color: #000000;
}

.related-product-area .swiper-container-horizontal {
    width: 100%;
}

.hover-bottom-border:hover {
    background: #000000;
}

/* .box-head-title {
    padding: 30% 0;
} */

.hover-bottom-border:hover button.btn, .hover-bottom-border:hover h4 {
    color: #fff;
}

.home-banner-img {
    background-position: top !important;
    background-size: 100% !important;
}

.label-rounded {
    border: 1px solid #dee2e6;
    margin: 0 5px;
    padding: 5px 15px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}


.cat-head-list a.set-home-dropdown-button:first-child {
    padding-left: 24px;
}
.cat-head-list a.set-home-dropdown-button:nth-last-of-type(3) {
    padding-right: 24px;
}

@media only screen and (max-width: 767px) {
	.cat-head-list {
		overflow: hidden;
		overflow-x: scroll;
	}
    .banner-info-wrapper {
        padding-top: 0;
    }
    .banner-info-wrapper .banner-info>img {
        margin-bottom: 20px;
    }
	.col-xs-6 {
        flex: 0 0 50%;
        max-width: 50%;
	}
	.home-slider-text h1.animated {
		font-size: 20px;
	}
	.home-slider-text p {
		font-size: 15px;
		margin-bottom: 20px;
	}
	.home-page-slider-img .pro-img {
		min-height: 150px;
	}
    .cat-head-list a.set-home-dropdown-button {
        padding: 8px 10px;
        font-size: 13px;
    }

    .popular-brands {
        margin-bottom: 30px;
    }
    .recent-viewed {
        display: flex;
        flex-wrap: wrap;
    }
    .recent-viewed div[class^="col-"] {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .about-info .about-information{
        padding-bottom: 30px;
    }
    .make-offer-modal .modal-dialog{
        width: 100% !important;
    }
    .recapctcha{
        width: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    
}

.offcanvas-mobile-search-area a {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    padding: 0;
	font-size: 20px;
}

.header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a span.count-style,
.header-right-wrap.header-right-wrap-white .same-style.account-setting > button span.count-style{
	background-color: #000;
    color: #fff;
}

.header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a,
.header-right-wrap.header-right-wrap-white .same-style.account-setting > button{
	color: #000
}

.cat-head-list a.set-home-dropdown-button {
    text-transform: uppercase;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    position: relative;
    background: #EDEBE1;
}

.header-right-wrap .same-style.header-search .search-content {
    position: absolute;
    top: -36px;
	box-shadow: none;
}

.header-right-wrap .same-style.header-search .search-content form input {
    height: unset;
}

.home-banner-img .slider-content-5 .animated,
.home-banner-img .slider-content-5 p {
    color: #3a434a !important;
}

.home-banner-img .slider-content-5 a.animated {
    color: #fff !important;
    background: #3a434a;
}

.register-error-msg{
	position:absolute;
	bottom:5px;
	display:block;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single {
    /* width: 25%; */
    margin: 3px;
    border: 1px solid #f3f3f3;
    text-align: center;
	background: none;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single.bg-gray {
    background: #efefef;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single.bg-gray:hover {
    background: #000000;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover .size-name .text-primary {
    color: #fff !important;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name .text-primary {
    color: #fff !important;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name {
    background-color: #000000;
    color: #ffffff;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover {
    background-color: #000000;
    color: #fff;
}

.product-details-content .pro-details-size-color.radio-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name{
	    background-color: #c1c1c1;
		color: #ffffff;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name {
    margin-bottom: 0;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name .text-primary {
    font-weight: 500;
    font-family: 'Poppins';
}

.table-design .table-responsive-md {
    background: #fff;
    padding: 25px;
}

.product-details-content .pro-details-quality .pro-details-cart a, .product-details-content .pro-details-quality .pro-details-cart button{
	z-index:1;
}

.tabs-left {
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.tabs-left>li {
  float: none;
 margin:0px;
  
}

.slider-content-5 h1 {
    font-size: 50px;
}

.product-img-badges span.purple {
    display: initial;
    margin: 3px;
}

.myproduct-bgcolor {
	display: none;
}
.myproduct-bgcolor.active {
    display: flex;
}

.not-allowed {
     cursor: not-allowed !important;
}

span.gallery-close {
    border-radius: 10px;
    position: absolute;
    right: -7px;
    margin-top: -7px;
    background: #ff00008a;
    color: #fff;
    padding: 0 5px;
	cursor: pointer;
}

.sell-product .pro-details-size-content--single input.sizes-status:disabled + .size-name {
    background: #efefef !important;
}

.buyseller-field.switch-field label {
    width: 49%;
    padding: 15px;
    font-size: 17px;
    font-weight: bold;
}

.text-gray {
    color: gray;
}
.nav-tabs .nav-link.active {
    color: #000000;
    background-color: #dcdcdc;
    border-color: #dee2e6 #dee2e6 #fff;
}

.header-right-wrap .same-style .notify-sec {
    max-height: 250px;
    width: 300px;
	overflow: hidden;
    overflow-y: scroll;
}
.notify-text-description {
    font-size: 12px;
    line-height: 17px;
}

.step-sec {
    display: none;
}

.step-sec.active {
    display: flex;
}

.login-register-wrapper .login-form-container .login-register-form form select {
    background-color: transparent;
    border: 1px solid #ebebeb;
    color: #333;
    font-size: 14px;
    height: 45px;
    margin-bottom: 30px;
    padding: 0 15px;
}

.select__control.css-1s2u09g-control {
    border-radius: 0;
    border-color: hsl(0deg 0% 92%);
}

.login-register-wrapper .login-form-container .login-register-form form .css-7pwich-Input input {
    height: 40px;
}

.cursor-pointer {
    cursor: pointer;
}

.ck.ck-toolbar {
    border: 1px solid #dee2e6 !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #dee2e6 !important;
}
.select__input-container.css-7pwich-Input {
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.manually-product input.sizes-status {
    display: none;
}

[dir=ltr] .ck.ck-dropdown .ck-dropdown__arrow{
	z-index:0;
}

.dataTables_filter label, .dataTables_length label {
    display: flex;
}

.dataTables_length label select, .dataTables_filter label input[type="search"] {
    height: 30px !important;
    margin: 0 10px !important;
}

button.btn-coupon-apply {
    padding: 12px !important;
}

.product-large-image-wrapper.used-image .react_lightgallery_item {
    top: -3px;
    right: 15px;
}
.product-large-image-wrapper.used-image .react_lightgallery_item i.pe-7s-expand1 {
    font-size: 20px;
}
button.filter-padding {
    padding: 12px 25px !important;
    margin-top: 8px;
}

.my-sell-accordion h3.panel-title {
    padding: 0 10px;
}

.my-sell-accordion .single-my-account h3.panel-title::before {
    top: 0;
}

.addproduct-error-msg {
    position: absolute;
    display: block;
    /* bottom: 7px; */
}

.fullscreen {
    z-index: 1000000;
}
.fullscreen .modal-content {
    height: 100%;
}
.fullscreen .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.fullscreen-right {
    z-index: 1000000;
}
.fullscreen-right .modal-content {
    height: 100%;
}
.fullscreen-right .modal-dialog {
    width: 35%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
}
.fullscreen-right .modal-body {
    overflow: hidden;
    overflow-y: scroll;
}

.product-details-content .pro-details-quality .pro-details-cart button.bg-none {
    background: none;
    color: #000;
    border: 1px solid black;
}

.set-home-dropdown, .set-home-dropdown:focus, .set-home-dropdown:hover {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
	color: #000 !important;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.main-menu.menu-white nav ul li > a {
    color: #555252;
}

.stick .main-menu nav ul li a.set-home-dropdown-button {
    line-height: 30px !important;
}

.breadcrumb-area {
    padding: 15px;
}

/* .breadcrumb-area .breadcrumb-content {
    text-align: left !important;
} */

.stick .main-menu.menu-white nav ul li button {
    color: #555252 !important;
}

.btn-follow {
    position: absolute;
    right: 5%;
    cursor: pointer;
	margin-top: -25px;
}

span.btn-top-model {
    margin-right: 15px;
}

.model-width-25 .modal-dialog {
    width: 25%;
}

.modal.show .modal-dialog li {
    width: 50%;
    display: inline-block;
    text-align: center;
    font-size: 35px;
    padding: 10px;
}

.notify-border {
    border-bottom: 2px dotted black;
    flex: 1 1 0%;
    margin-left: 10px;
    margin-right: 10px;
}

.notify-sec{
	display: flex;
	flex-direction: row;
	-webkit-box-align: baseline;
	align-items: baseline;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.notify-page input[type="radio"] {
    display: none;
}

.notify-page .btn-group label {
    font-size: 13px;
}

/* start my account page side bar */

.my-account-side {
    padding: 5px 0;
    color: #000;
}

.my-account-side p {
    line-height: 18px;
}

.my-account-side:hover {
    background: #dcdcdc;
	cursor:pointer;
}
.my-account-side.active {
    background: #dcdcdc;
}

/* end my account page side bar */

/* start popup */

.popover-title {
  line-height: 0px;
  padding: 15px 0;
}

.popover-wrapper {
  position: relative;
}
.popover-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -130px;
  transform: translate(0, 10px);
  background-color: #000000;
  padding: 0 5px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}
.popover-content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(0% - 10px);
  border-style: solid;
  border-width: 10px 0px 10px 10px;
  border-color: transparent transparent #00000000 black;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover-wrapper:hover .popover-content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover-message {
  text-align: center;
}

/* end popup */

/* start switch radio */


.switch-field {
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #333437;
    box-shadow: none;
    color: #fff;
}

/* end switch radio */


/* start tooltip */

.tooltip-cus {
	color: #858796; outline: none;
	cursor: help; text-decoration: none;
	position: relative;
}
.tooltip-cus span {
	position: absolute;
	display:none;
}
.tooltip-cus:hover span {
	border-radius: 5px 5px; 
	-moz-border-radius: 5px; 
	-webkit-border-radius: 5px; 
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); 
	-webkit-box-shadow: 5px 5px rgba(0, 0, 0, 0.1); 
	-moz-box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
	position: absolute; top: 2em; 
	z-index: 99;
	margin-left: 0; width: 250px;
	display:block;
}
.tooltip-cus:hover img {
	border: 0; margin: -10px 0 0 -55px;
	float: left; position: absolute;
}
.tooltip-cus:hover em {
	font-family: Candara, Tahoma, Geneva, sans-serif; font-size: 1.2em; font-weight: bold;
	display: block; padding: 0.2em 0 0.6em 0;
}
.classic { padding: 0.8em 1em; }
.custom { padding: 10px 15px; }
* html a:hover { background: transparent; }
.classic {background: #FFFFAA; border: 1px solid #FFAD33; }
.critical { background: #FFCCAA; border: 1px solid #FF3334;	}
.help { background: #fdfdfd; border: 1px solid #c1c1c1;	}
.info { background: #9FDAEE; border: 1px solid #2BB0D7;	}
.warning { background: #FFFFAA; border: 1px solid #FFAD33; }


/* end tooltip  */


/* start file upload */

.file-area {
  width: 100%;
  position: relative;
}
.file-area input[type=file] {
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.file-area .file-dummy {
  width: 100%;
  height: 130px;
  background: url(/assets/img/product/dummy.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  border: 2px dashed rgb(204 200 200 / 99%);
  text-align: center;
  transition: background 0.3s ease-in-out;
}

.file-area:hover .file-dummy {
  background: rgba(255, 255, 255, 0.1);
}
.file-area input[type=file]:focus + .file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

.file-area input[type=file]:valid + .file-dummy .success {
  display: inline-block;
}
.file-area input[type=file]:valid + .file-dummy .default {
  display: none;
}

/* end file upload */ 

/* .btnbuysellShow {
    position: absolute;
    top: 0;
    right: 3%;
} */

/* start loader */ 

.container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #11111147;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000000;
    background-size: 10%;	
}

.loader-sec{
	display:none !important;
}

.container-loader .loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

.container-loader .loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    pointer-events: none;
    animation: animateThreads 5s linear infinite;
}

.container-loader .loader span:nth-child(1) {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
}

.container-loader .loader span:nth-child(2) {
    animation-direction: reverse;
    border-radius: 67% 33% 70% 30% / 75% 65% 35% 25%;
}

.container-loader .loader span:nth-child(3) {
    animation-duration: 3s;
    border-radius: 52% 48% 70% 30% / 41% 65% 35% 59%;
}

@keyframes animateThreads {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container-loader .loader h2 {
    color: #fff;
    font-weight: 500;
}

/* end loader */ 



/* start Vertical Steps*/
.list-group.vertical-steps{
  padding-left:10px;
}
.list-group.vertical-steps .list-group-item{
  border:none;
  border-left:3px solid #ece5dd;
  box-sizing:border-box;
  border-radius:0;
  counter-increment: step-counter;
  display: unset !important;
  text-align: left !important;
  font-size: unset !important;
  padding-left:20px !important;
  padding-right:0px !important;
  padding-bottom:20px !important;  
  padding-top:0px !important;
}
.list-group.vertical-steps .list-group-item.active{
  background-color:transparent;
  color:inherit;
}
.list-group.vertical-steps .list-group-item:last-child{
  border-left:3px solid transparent;
  padding-bottom:0;
}
.list-group.vertical-steps .list-group-item::before {
  border-radius: 50%;
  background-color:#bc8459;
  color:#555;
  content: counter(step-counter);
  display:inline-block;
  float:left;
  height:25px;
  line-height:25px;
  margin-left:-35px;
  text-align:center;  
  width:25px;  
}
.list-group.vertical-steps .list-group-item span,
.list-group.vertical-steps .list-group-item a{
  display:block;
  overflow:hidden;
  padding-top:2px;
}

.list-group.vertical-steps .list-group-item.active::before{
  background-color:#bc8459;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed{
  border-left:3px dashed #bc8459;
}
.list-group.vertical-steps .list-group-item.completed::before{
  background-color:#bc8459;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed:last-child{
  border-left:3px dashed transparent;
}
.list-group.vertical-steps .list-group-item i {
    color: #bc8459;
}
.sell-understand-process {
    background: #f1f1f1;
    overflow: hidden;
    overflow-y: scroll;
}
.list-group-item.completed {
    background: transparent;
}
/* end Vertical Steps*/

/*  start range */

.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

.range::-webkit-slider-runnable-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-fill-upper { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip { display: none; /* display and visibility only */ }

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range::-ms-thumb { 
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px; 
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}

output {
  border: 1px solid #d7dbdd;
  color: #333;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: .4em .6em;
  border-radius: 3px;
}


/* end range */

.add-buyer-size-page-dropdown .single-my-account h3.panel-title::before {
    top: 9px;
}

.add-buyer-size-page-dropdown .card-body {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.btn-black-14 {
    border: 0;
    background: black;
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
}

.model-width-50 .modal-dialog {
    width: 50%;
}

.z-index-100{
	z-index:100;
}

.btn-black {
    border: 0;
    padding: 5px 40px;
    background: #343538;
    color: #fff;
    font-weight: bold;
}

/* start shipPDF */

.page-no {
    padding: 10px 15px;
    background: #40ac33;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
}

.ship-title {
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
}

.shipdate {
    color: #fff;
    background: #be1c2b;
    font-size: 24px;
    font-weight: bold;
}

.shiporder-no {
    font-size: 30px;
    font-weight: bold;
    margin-top: 5px;
}

.ship-guid {padding-left: 30px;}

.modal.show .modal-dialog .ship-guid li {
    width: 100%;
    font-size: 12px;
    text-align: left;
    display: list-item;
    font-weight: 600;
    padding: 5px 10px;
}

.modal.show .modal-dialog .ship-guid li::marker {
    color: #8b837a;
    font-size: 17px;
}

.vertical-align-bottom {
    padding-top: 8%;
}
.line-height-1 {
    line-height: 15px;
	text-transform: uppercase;
}

.checkbox-medium {
    width: 50%;
}

.size-home-small .modal-content {
    margin: auto;
}
.kep-login-facebook.metro {
    width: 100%;
    padding: 0.5rem;
    font-size: 14px;
    font-family: "Suisse Intl", sans-serif;
    font-weight: 200;
    background: transparent;
    border: 1px solid #dee2e6;
    color: #757575;
    text-transform: capitalize;
}

.kep-login-facebook.metro i {
    color: #3b5998;
}

/* end shipPDF */


.header-right-wrap .same-style .account-dropdown.add-width {
    width: 400px;
}

.border-solid-2px {
    border: 3px solid;
    padding: 10px;
    text-align: center;
}
.border-gold {
    border-color: #FF9D58;
    color: #FF9D58;
}

.list-style-type{
	list-style-type:disc;
}

.text-gold {
    color: #ff9d58;
}

.border-black {
    border: 1px solid black;
}

.sidebar-widget-list .size-dynamic-list {
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}

.text-uppercase {
    text-transform: uppercase;
}

.bg-gray{
	background:#242424;
}

.account-setting:hover .account-dropdown, 
.account-setting .account-dropdown:hover {
	visibility: visible;
	opacity: 1;
	transform: rotateX(0deg);
}

.cut-sec {
    border: 1px dashed #c1c1c1;
}

i.fa.fa-scissors {
    position: absolute;
    margin-top: -24px;
}

.second-page-margin {
    margin-top: 50% !important;
}

.sell-product-notes ul {
    list-style-type: disc;
    font-size: 15px;
    padding-left: 17px;
}

.sell-product-notes ul li {
    margin-bottom: 10px;
}


.scroll-modal-body .modal-body {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.cursor-not-allwed {
    cursor: not-allowed;
}

.search-results-pf {
    background: #fff;
    padding: 15px 15px;
	margin-top: 5px;
}

.btn-outline-black {
    border: 1px solid #343538;
    padding: 5px 40px;
}

.font-width-label-radio label {
    font-size: 12px;
    width: 90px;
}

.apexcharts-toolbar {
    display: none !important;
}

.font-size-12 {
    font-size: 12px;
}

.search-results-pf .product-pf-result {
	margin-bottom: 10px;
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 10px;
}

/* start loading */

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display:none;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgb(255 255 255), rgb(255 255 255));

  background: -webkit-radial-gradient(rgb(255 255 255), rgb(255 255 255));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
	-webkit-box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* end loading */

@media only screen and (max-width: 767px) {
	.desktop-sec {
		display: none !important;
	}
	
	.mobile-sec {
		display: block !important;
	}
    /* .recent-product .product-wrap-3 .product-img a img{
        height: 180px !important;
    } */
    /* .recent-viewed .product-wrap-3 .product-img a img{
        height: 180px !important;
    } */
    .product-slider-img .product-wrap-3 .product-img a img{
        height: 180px !important;
    }
    .popular-brands {
        margin-top: 0;
    }
    .size-home-small .modal-content {
        width: 340px;
    }
    .banner-info-wrapper.hm5-section-padding div[class^="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
    .heading-title-main{
        font-size: 19px !important;
    }
    .product-container p{
        padding: 0 !important;
    }
    .about-info{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
}

@media only screen and (min-width: 768px) {
    .cat-head-list a.set-home-dropdown-button {
        padding: 10px 20px;
        font-size: 17px;
    }
	.desktop-sec {
		display: block !important;
	}
	
	.mobile-sec {
		display: none !important;
	}
    .popular-brands {
        margin: 40px 0;
    }
    .size-home-small .modal-content {
        width: 380px;
    }
    .all-product-banner-img,
    .all-product-banner-img img {
        height: 266px;
    }
}
.user-profile{
    margin-left: 25px !important;
}
.logo.logo-hm5{
    margin-top: 9px !important;
    margin-bottom: 10px !important;

}
.logo{
    margin-top: 0px !important;
}
.footer-top {
    /* background-color: #1d1b1c; */
    color: #fff;
    padding-top: 40px !important;
}
.footer-area {
    /* background-image: url("/assets/img/bg/footer-bg-1.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; */
    /* background-position: 77% 0; */
    background-position: 17% 0;
    position: relative;
    background-color: #272626;
}

.footer-area:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(358deg, rgb(0 0 0 / 78%) 50%, rgb(0 0 0 / 29%) 100%);
}
.footer-area .top,
.footer-top,
.footer-bottom {
    z-index: 1;
    position: relative;
}
.footer-bottom{
    /* background-color: #1d1b1c; */
    color: #fff;
    /* padding: 25px 0 25px 70px; */
    padding: 25px;
}
.footer-bottom a{
    color: #fff;

}
.footer-top .footer-social ul li a {
    color: #fff;
   
}
.footer-area .top {
    background-color: rgb(237 235 225 / 48%);
    text-align: center;
    color: #000;
    margin-bottom: 0;
    padding: 40px 0 40px;
}
.account-dropdown .set-home-dropdown-button:hover{
    color: #000 !important;
}
.main-menu nav ul li > a:hover{
    color: #000 !important;
}
.header-right-wrap .same-style.account-setting > button:hover{
    color: #000 !important;
}

.header-right-wrap .same-style .account-dropdown:hover{
    color: #000 !important;
}
.footer-top .footer-social ul li a:hover{
    color: #909090;
}
.single-mission{
    background: #f3f3f3;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #c1c1c1 !important;
}
.contact-form .contact-form-style button:hover{
    background-color: #f2f2f2;
    color: #000;
    border: 1px solid #c1c1c1;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a:hover{
    color: #000;
}
.contact-social ul li a:hover{
    color: #000;
}
.login-register-wrapper .login-register-tab-list .nav-item a.active h4{
    color: #000000 !important;
}
.login-register-wrapper .login-register-tab-list .nav-item a h4{
    color: #c1c1c1 !important;
}
/* .subscribe-area-3 {
    margin-top: 60px;
} */
.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active{
    margin: auto 0;
    background: white;
}
.product-large-image-wrapper .product-image img{
    object-fit: cover;
}
.recent-product .product-wrap-3 .product-img img,
.recent-viewed .product-wrap-3 .product-img img,
.product-slider-img .product-wrap-3 .product-img img {
    height: 320px;
    object-fit: cover;
}
@media (max-width: 1535.98px) {
    .popular-brands .pop-brands-slider img {
        /* height: 160px; */
    }
}
@media (min-width: 1400px) and (max-width: 1536px) {
    .recent-product .product-wrap-3 .product-img img,
    .recent-viewed .product-wrap-3 .product-img img,
    .product-slider-img .product-wrap-3 .product-img img {
        height: 350px;
    }
    .popular-brands .pop-brands-slider img {
        /* height: 135px; */
    }
}
@media (min-width: 1537px) {
    .recent-product .product-wrap-3 .product-img img,
    .recent-viewed .product-wrap-3 .product-img img,
    .product-slider-img .product-wrap-3 .product-img img {
        height: 450px;
    }
    .popular-brands .pop-brands-slider img {
        /* height: 180px; */
    }
}
.related-product .product-wrap .product-img img{
    /* height: 260px; */
    object-fit: cover;
}
.popular-product{
    background-color: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 80px;
    margin-top: 20px;
}
/* .swiper-button-disabled{
    opacity: 1 !important;
} */
.product-detail-info .nav-item .nav-link{
    border: 1px solid #ddd;
    padding: 11px 15px;
    color: #404040;
    margin: 0 5px 0 0;
    font-weight: 700;
    font-size: inherit;
    line-height: inherit;
}
/* .product-detail-info{
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    
} */
.authentic .label-rounded{
    /* border: none;
    font-size: 18px;
    font-weight: 600; */
}
.condition{
    font-size: 17px;
    font-weight: 600;
    margin-right: 15px;
}
.product-condition .label-rounded{
    /* border: none;
    font-size: 17px;
    font-weight: 100; */
    
}
.popular-brands {
    padding: 30px 0 30px;
    background-color: #272626;
    position: relative;
    /* background-image: url("/assets/img/banner/popular-brands.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
    overflow: hidden;
    /* background-image: url();
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; */
    margin-bottom: 0;
}
.popular-brands:after {
    content: '';
    /* position: absolute; */
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(358deg, rgb(0 0 0 / 80%) 50%, rgb(0 0 0 / 43%) 100%);
}
/* .popular-brands .heading-title-main {
    color: #fff !important;
    text-shadow: none;
}
.popular-brands .see-all-btn a {
    text-shadow: none;
    color: #fff;
} */
.popular-brands .heading-title b {
    background-color: #000;
    display: none;
}
.popular-brands .heading-title {
    z-index: 1;
}
.popular-brands h3{
    color: #fff;
}
.popular-brands a{
    color: #fff;
}

/* .subscribe-area-3{
    padding-bottom: 60px;
} */
.main-menu {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
}
.main-menu ul{
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
    width: 100%;
    position: relative;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 17px;
}
.row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.product-content h3{
    font-size: 20px !important;
}
.heading-title {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-gap: 10px;
}
.heading-title.padding {
    padding-top: 20px;
}
.heading-title.space {
    margin: 20px 0 40px;
}
.heading-title b {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 1px;
    opacity: .1;
    background-color: #1d1b1c;
}
.heading-title-main {
    text-transform: uppercase;
    font-family: 'Crimson Text', 'serif';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.sh-initial .heading-title-main,
.sh-initial .see-all-btn a {
    text-shadow: none;
}
.see-all-btn {
    font-size: 1rem;   
    font-weight: 500;
    position: relative;
    text-transform: uppercase;
}
.see-all-btn a {
    color: #555;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.see-all-btn a:before {
    position: absolute;
    left: 0;
    bottom: 2px;
    content: "";
    height: 1px;
    width: 100%;
    will-change: transform;
    background: currentColor;
    transform-origin: right center;
    transition: transform .5s cubic-bezier(1,0,0,1);
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
}
.see-all-btn a:hover:before {
    transform-origin: left center;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
}
.hover-btns,
.hover-view-btn,
.hover-view-btn .btn:hover,
.product-quick-title:hover {
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
}
.product-quick-title a{
    color: #696969;
}
.product-quick-title a:hover {
    color: #000;
}
.product-wrap .product-content .code {
    color: #000;
    font-size: 13px;
    font-weight: 500;

}
.product-quick-title.lg {
    font-size: 20px;
}
.hover-btns:hover .product-img a img {
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -ms-transform: scale(1.03);
    -o-transform: scale(1.03);
}

.hover-btns .product-img .pro-img {
    overflow: hidden;
    position: relative;

}
.hover-btns:hover .hover-view-btn {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}
.hover-view-btn {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
}
.hover-view-btn .btn {
    font-size: 16px;
    color: #fff;
    background-color: rgb(3 4 28 / 88%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: inline-block;
    padding: 3px 25px;
    text-align: center;
    width: 100%;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    border-radius: 0;
}
.hover-view-btn .btn:hover {
    color: #fff;
    background-color: rgb(210 166 59 / 75%);
}
.product-quick-title:hover {
    color: #000;
}
.product-quick-title {
    font-size: 15px;
    color: #696969;
    line-height: 1.5;
    margin-top: 10px;
    height: 35px;
    margin-bottom: 10px;
}
.circle-wrap-parent {
    position: relative;
}
.circle-wrap-a,
.circle-wrap-b {
    position: absolute;
    /* transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%); */
}
.circle-wrap-a {
    left: 0;
    top: -60%;
}
.circle-wrap-b {
    right: 0;
    bottom: 60%;
}
.circle-wrap-a .circle-1 {
    left: 0;
}
.circle-wrap-b .circle-2 {
    right: 0;
}
.circle-wrap-a .circle-1 {
    animation-delay: 1s;
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
}
.circle-1, .circle-2 {
    position: absolute;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    background-color: rgb(210 166 59 / 32%);
    animation: tp-hotspot 3s cubic-bezier(.4,0,1,1) infinite;
    animation-delay: 0s;
}

@keyframes tp-hotspot {
    0% {
        transform: scale(.8);
        opacity: 0
    }

    70% {
        opacity: 1
    }

    to {
        transform: scale(1.3);
        opacity: 0
    }
}
/* .pop-brands-slider .slider-home-btn {
    border: 0;
    padding: 0 25px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    font-size: 30px;
	top:60%;
} */
/* .pop-brands-slider {
    padding-top: 20px;
} */
.pop-brands-slider .slider-home-btn.swiper-button-disabled {
    display: none;
}
.pop-brands-slider .slider-home-btn {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    display: flex;
    width: 40px;
    height: 40px;
    font-size: 40px;
}
.pop-brands-slider .slider-home-btn {
    visibility: hidden;
    opacity: 0;
}
.pop-brands-slider:hover .slider-home-btn {
    opacity: 1;
    visibility: visible;
}
.pop-brands-slider .slider-home-btn.swiper-button-next {
    right: 2%;
}
.pop-brands-slider .slider-home-btn.swiper-button-prev {
    left: 2%;
}
.pop-brands-slider .slider-home-btn:hover {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
}
.popular-brands .pop-brands-slider img {
    object-fit: cover;
    border: 0;
    padding: 0;
}
.cat-head-list {
    /*z-index: 100;
    width: 100%;
    margin-top: 100px;*/
    /* background: #EDEBE1;
    padding: 10px; */
    text-align: center;
    /*margin-bottom: 15px;
    */-webkit-transform-origin: right center;
    transform-origin: right center;
    max-width: 795px;
    /* width: auto; */
    margin: 0 auto;
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    display: flex;
    justify-content: center;
}

.sticky-bar.stick .cat-head-list {
    position: fixed;
    right: 0;
    z-index: 10;
    /* width: 318px; */
    border: 1px solid #d3d3d3;
    transform: translate(-21px,21px) rotate(-90deg);
    -webkit-transform: translate(-21px,21px) rotate(-90deg);
    -moz-transform: translate(-21px,21px) rotate(-90deg);
    -ms-transform: translate(-21px,21px) rotate(-90deg);
    -o-transform: translate(-21px,21px) rotate(-90deg);
    width: auto;
}
.sticky-bar.stick .cat-head-list a {
    padding: 10px;
}
.sticky-bar.stick .cat-head-list a:nth-last-child(1),
.sticky-bar.stick .cat-head-list a:nth-last-child(2) {
    display: none;
}
.main-menu nav ul li .dropdown .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #696969;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.main-menu nav ul li .dropdown .dropdown-menu {
    box-shadow: 0 20px 30px rgba(3,4,28,.1);
    border: 0;
 }
 .main-menu nav ul li .dropdown a,
 .main-menu nav ul li .dropdown .dropdown-menu a {
    color: #696969;

 }
 .main-menu nav ul li .dropdown:hover a,
 .main-menu nav ul li .dropdown .dropdown-menu:hover a {
    color: #000;
 }
 .main-menu nav ul li .dropdown .dropdown-menu:hover a {
    color: #696969;
 }
 /* .hover-btns:before {
    border-style: solid;
    border-width: 0 30px 40px 0;
    border-color: transparent #121212 transparent transparent;
    right: 0;
    top: 0;
 } */
 .hover-btns:hover:after, .hover-btns:hover:before {
    opacity: 1;
    visibility: visible;
    z-index: 1;
}
.hover-btns:after, .hover-btns:before {
    content: '';
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}
.footer-widget .footer-widget-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    margin-bottom: 26px;
    padding-top: 3px;
}
.footer-widget ul li {
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 13px;
    /* font-family: 'Arial', sans-serif; */
    font-weight: 300;
}
.footer-widget ul li a {
    display: inline-block;
    position: relative;
    color: #fff;
    text-transform: uppercase;
}
.footer-widget ul li a:before,
.cat-head-list a.set-home-dropdown-button:before {
    position: absolute;
    left: 0;
    bottom: -3px;
    content: "";
    height: 1px;
    width: 100%;
    will-change: transform;
    background: currentColor;
    transform-origin: right center;
    transition: transform .5s cubic-bezier(1,0,0,1);
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
}
.footer-widget ul li a:hover:before,
.cat-head-list a.set-home-dropdown-button:hover:before {
    transform-origin: left center;
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
}
.footer-social {
    padding-top: 10px;
}
.footer-bottom .bottom-link {
    margin-right: 10px;
    margin-left: 10px;
}
.footer-bottom .bottom-link:first-child {
    margin-left: 0;
}
.footer-bottom .bottom-link:last-child {
    margin-right: 0;
}
.sidebar-widget-list .size-dynamic-list::-webkit-scrollbar {
    width: 6px;
    height:6px;
}
  
  .sidebar-widget-list .size-dynamic-list::-webkit-scrollbar-track {
    background: #f1f1f1;

}
   
  .sidebar-widget-list .size-dynamic-list::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
  .sidebar-widget-list .size-dynamic-list::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }
  .sidebar-style.sticky-position {
    position: sticky;
    top: 8rem;
    overflow: auto;
    height: 80vh;
    padding-bottom: 20px;
}
.sidebar-style.sticky-position::-webkit-scrollbar{
    visibility: hidden;
}
.all-product-banner-img {
    background-image: url("/assets/img/banner/all-product-banner.jpg");
    background-position: center;
    background-repeat: no-repeat;
    /* height: 376px; */
    background-size: cover;
}
.all-product-banner-img img {
    /* height: 376px;  */
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.all-product-heading-wrap .heading {
    font-family: 'Arial', sans-serif;
    font-size: 44px;
    font-weight: bold;
    text-align: center;
    margin: 60px 0 0;
}  
.code.space {
    margin-left: 10px;
    font-size: 13px;
}
.code.grid {
font-size: 16px;
margin-top: 40px !important;
margin-bottom: 20px;
color: #696969;
}
.footer-widget {
    margin-bottom: 20px;
    
}
  .subscribe-btn {
    position: relative;
    border-radius: 0 !important;
  }
  .subscribe-btn p {
    margin: 0;
    position: relative;
    font-size: 15px;
    color: #000;
  }
  
  
  .subscribe-btn p::before {
    position: absolute;
    /*   box-sizing: border-box; */
    content: "Subscribe";
    width: 0%;
    inset: 0;   
    color: #d2a63b;
    overflow: hidden;
    transition: 0.3s ease-out;
  }
  
  .subscribe-btn:hover::after {
    width: 100%;
  }
  
  .subscribe-btn:hover p::before {
    width: 100%;
  }
  .product-quick-title.lg.no-hover {
    color: #000;
  } 
  .subscribe-btn:hover {
    border-color: #d2a63b !important;
  }
.modal-content {
    border: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.modal.show {
    cursor: url("/assets/img/cursor-close.svg") 28 28,auto;
}
.modal.show .modal-dialog:hover {
    cursor: auto;
}
.product-quickview-modal-wrapper .modal-header {
    display: none;
}

.btn-hover .btn {
    position: relative;
    transition: all 0.5s ease-in-out 0s;
    font-size: 14px;
    color: #343538;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1;
    background-color: #343538;
    color: #fff;
    padding: 14px 32px 15px;
    border: 1px solid transparent;
    z-index: 1;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}  
.btn-hover .btn:hover {
    border: 1px solid #1866a8;
}
.box-head-title .heading-title-main,
.des-after .heading-title-main {
    margin-bottom: 10px;
}
.box-head-title .code,
.des-after .code {
    margin-bottom: 20px;
}

.box-head-title .authentic .label-rounded {
    background-color:  #EDEBE1;
    border-color: #EDEBE1;
    margin: 0;
}
.box-head-title .product-condition {
    margin-bottom: 20px;

}
.box-head-title .product-condition:first-child {
    padding-top: 20px;

}
.box-head-title .product-condition:last-child {
    margin-bottom: 40px;
}
.box-head-title .product-condition:last-child .label-rounded {
    background-color: #000;
    border-color: #000;
    color: #fff;
}
.box-head-title .product-condition .condition {
    margin-right: 5px;
}
.product-details-content .btn-hover {
    margin-bottom: 30px;

}
/* .product-details-content .btn-hover .btn {
    display: block;
    width: 100%;
} */
.product-details-content div>ul>li>strong,
.description-review-bottom .tab-pane ul li strong,
.description-review-bottom .tab-pane ul li span {
    position: relative;
    white-space: nowrap;
}
.product-details-content div>ul>li>strong::after,
/* .description-review-bottom .tab-pane ul li strong::after, */
.description-review-bottom .tab-pane ul li span::after {
    content: ':';
    margin-left: 1px;
}
.product-details-content div>ul>li,
.description-review-bottom .tab-pane ul li {
    display: flex;
    gap: 10px;
    margin-bottom: 6px;
}
.product-details-content h1,
.product-details-content h2,
.product-details-content h3,
.product-details-content h4,
.product-details-content h5,
.product-details-content h6 {
    line-height: 1.3;
    font-weight: 600;
}
.product-details-content h1 {
    font-size: 19px;
}
.product-details-content h2 {
    font-size: 18px;
}
.product-details-content h3 {
    font-size: 17px;
}
.product-details-content h4 {
    font-size: 16px;
}
.product-details-content h5 {
    font-size: 15px;
}
.product-details-content h6 {
    font-size: 14px;
}
.product-detail-info .nav-item .nav-link {
    border-bottom: 3px solid #ddd;
}
.des-after>.btn-hover {
    margin-bottom: 30px;
}
.btn-hover.after-pd .btnbuysellShow {
    font-size: 10px;
    text-transform: capitalize;
    padding: 7px;
}
.des-after .btnbuysellShow i {
    margin-right: 5px;
}
.des-after .single-my-account h3.panel-title {
    padding: 6px 26px 6px 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}
.des-after .single-my-account .card-body {
    padding: 15px;

}
.des-after .pro-details-size {
    width: 100%;
}
.des-after .single-my-account h3.panel-title::before {
    top: 6px;
}
.des-after .single-my-account h3.panel-title>label {
    margin-bottom: 0;
} 
.product-details-content .des-after .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single {
    width: auto;
    margin: 0;
    text-align: left;

}
.product-details-content .des-after .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single:hover {
    color: #000;
    border: 1px solid #555;

}
.product-details-content .des-after .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single.bg-gray:hover {
    color: #fff;

}
.product-details-content .des-after .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name {
    display: flex;
    align-items: center;
    gap: 40px;
}
.product-details-content .des-after .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name .inner {
    font-weight: 400;
}
.des-after .single-my-account {
    border-color: #ddd;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
}
.des-after .single-my-account h3.panel-title {
    background-color: #fff;

}
.size-home-small .close {
    display: none;
}
.size-home-small .modal-header {
    padding: 15px 20px;
    border-bottom: 0;
}
.size-home-small .modal-header {
    justify-content: center;
}
.size-home-small .modal-body {
    padding: 20px 20px 10px;
}
.size-home-small .modal-footer {
    padding: 10px 20px 20px;
    border-top: 0;
}
.size-home-small .form-control:focus {
    border-color: #000;
    box-shadow: none;
}
.description-review-bottom .tab-pane h1,
.description-review-bottom .tab-pane h2,
.description-review-bottom .tab-pane h3,
.description-review-bottom .tab-pane h4,
.description-review-bottom .tab-pane h5,
.description-review-bottom .tab-pane h6 {
    font-weight: 600;
    line-height: 1.4;
}
.description-review-bottom .tab-pane h1 {
    font-size: 18px;
}
.description-review-bottom .tab-pane h2 {
    font-size: 17px;
}
.description-review-bottom .tab-pane h3 {
    font-size: 16px;
}
.description-review-bottom .tab-pane h4 {
    font-size: 15px;
}
.description-review-bottom .tab-pane h5 {
    font-size: 14px;
}
.description-review-bottom .tab-pane h6 {
    font-size: 13px;
}
.description-review-bottom .tab-pane table {
    width: 100% !important;
}
.description-review-bottom .tab-pane ul {
    margin-bottom: 12px;
}
.description-review-bottom .tab-pane p {
    margin-bottom: 10px;
}
.description-review-bottom .tab-pane table,
.description-review-bottom .tab-pane table td,
.description-review-bottom .tab-pane table th {
    border: 1px solid #ddd;
}
.description-review-bottom .tab-pane table tr th,
.description-review-bottom .tab-pane table tr td {
    padding: 5px 10px;
}
/* .description-review-bottom .tab-pane table tr td:first-child,
.description-review-bottom .tab-pane table tr th:first-child {
    text-align: left !important;
} */
.description-review-bottom .tab-pane strong {
    font-weight: 600;
    color: #000;
}
.description-review-bottom .tab-pane table tr strong,
.description-review-bottom .tab-pane table tr strong {
    font-weight: 600;

}
footer .border-white {
    opacity: .2;
}
.product-quickview-modal-wrapper .sticky {
    position: sticky;
    top: 2rem;
}
.des-after .pro-details-quality.cst{
    margin: 0;
    display: block;
}
.des-after .pro-details-quality.cst .btn-hover {
    margin: 0;
}
.des-after .pro-details-quality.cst .btn-hover button {
    display: block;
    width: 100%;
    /* padding: 10px 11px; */
    padding: 14px 32px 15px;
    font-size: 14px;
}
.make-offer-modal {
    padding-left: 0 !important;
    top: 0;
    right: 0;
    line-height: 1.4;
}
.make-offer-modal .modal-dialog {
	        margin-right: 0;
	        padding: 0;
	        margin-top: 0;
	        max-width: 510px;
	        width: 510px;
	        margin-bottom: 0;
	        -webkit-animation: slide-out-right 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-right {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
  }
  
  
.make-offer-modal.show .modal-dialog {
    animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    -webkit-animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
 @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  
.make-offer-modal .modal-header .close {
    display: none;
}
.btn:focus, .btn.focus {
    box-shadow: none;
}



.dropdown-item.active, .dropdown-item:active {
    background-color: #ebebeb;
}
.description-review-area{
    padding: 80px 15px 90px 15px ;
}
.description-review-wrapper {
    overflow: hidden;
    
}
.description-review-wrapper .tab-pane {
	-webkit-animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

 @-webkit-keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
              transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
  }
  @keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
              transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
  }
  .product-quickview-modal-wrapper::-webkit-scrollbar,
  .size-home-small::-webkit-scrollbar{
    visibility: hidden;
}
  /* .product-quickview-modal-wrapper.show {
	        -webkit-animation: swing-in-top-bck 0.4s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-bck 0.4s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

 @-webkit-keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-bck {
    0% {
      -webkit-transform: rotateX(70deg);
              transform: rotateX(70deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  } */
.size-home-small.show {
	-webkit-animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-ver-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}  

 @-webkit-keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-ver-center {
    0% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      opacity: 1;
    }
  }
  
  /* .product-quickview-modal-wrapper, */
  .size-home-small {
	        -webkit-animation: scale-out-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-center 0.1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
 @-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
  }
  @keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
  }
  
  .moadl.fade {
    transition: opacity 0.30s linear;
    -webkit-transition: opacity 0.30s linear;
    -moz-transition: opacity 0.30s linear;
    -ms-transition: opacity 0.30s linear;
    -o-transition: opacity 0.30s linear;
}
.offer-section {
    align-items: end;
}
.offer-section .currency {
    margin-bottom: 0;
}
.offer-section .status {

}
.offer-section .offer-price {
    font-style: italic;
}
.offer-section .offer-price label {
    margin-bottom: 0;
}
.make-offer-modal .form-control {
    height: calc(1.5em + 0.75rem + 8px);
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    background-color: transparent;
    color: #fff;
    font-size: 13px;
}
.make-offer-modal .form-control:focus,
.make-offer-modal .btn-hover .btn:focus {
    border-color: #ffffff;
    box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 25%);
}
.make-offer-modal .modal-header {
    display: none;
}
.make-offer-modal .modal-content {
    background-color: #1d1b1c;
    color: #fff;
    height: 100vh;
    overflow: auto;
}
.make-offer-modal .modal-body {
    padding: 35px 45px;
}
.make-offer-modal .sub-title {
    font-size: 12px;
    margin-bottom: 20px;
}
.make-offer-modal .title-sm {
    text-transform: uppercase;
    margin-bottom: 18px;
}
.make-offer-modal .title {
    text-transform: uppercase;
    margin-bottom: 18px;
    font-size: 18px;
}
.make-offer-modal .title.pt {
    padding-top: 20px;
}
.make-offer-modal .small-info {
    font-size: 12px;
    margin-bottom: 15px;
}
.make-offer-modal .btn-hover .btn {
    display: block;
    width: 100%;
}
.make-offer-modal .btn-hover .btn:hover {
    border-color: #fff;
} 
.make-offer-modal .form-check-input {
    height: 16px;
    width: 16px;
    margin-top: 3px;
    cursor: pointer;
}
.make-offer-modal .btn-hover {
    padding-top: 16px;
}
.gallery-container .vertical {
    /* height: 550px; */
    margin-right: 1px;
}
/* .gallery-container .vertical .swiper-slide {
    height: 100px !important;
} */
.gallery-container .vertical .swiper-slide img {
    transition: 0.3s;
 }
 .gallery-container .swiper-button-prev,.gallery-container .swiper-button-next {
    position: absolute;
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    height: 65px;
    width: 40px;
    color: #e9e9e9;
    background: rgb(3 4 28 / 80%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0;
    border: 0;
}
.gallery-container .ht-swiper-button-nav:hover {
    background: rgb(3 4 28 / 100%);
}
.gallery-container .swiper-container-horizontal:hover .swiper-button-next.swiper-button-disabled, .gallery-container .swiper-container-horizontal:hover .swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none;
}
.gallery-container .swiper-button-prev {
    right: 40px;
    border-right: 1px solid #e9e9e9;
}
.gallery-container .swiper-container-horizontal:hover .ht-swiper-button-nav {
    opacity: 1;
}
.swiper-container:hover .slider-home-btn {
    opacity: 1;
    visibility: visible;
}
.nav-style-2 .slider-home-btn {
    height: 65px;
    width: 40px;
    color: #e9e9e9;
    background: rgb(3 4 28 / 80%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-style-2 .slider-home-btn.swiper-button-disabled {
    background: rgb(3 4 28 / 30%);
    /* pointer-events: none; */
    visibility: hidden;
}
.nav-style-2 .slider-home-btn:hover {
    background: rgb(3 4 28 / 100%);
}
.child .container-fluid:nth-last-child(1) .heading-title.clr b,
.child .container-fluid:nth-last-child(2) .heading-title.clr b,
.child .container-fluid:nth-last-child(3) .heading-title.clr b   {
    opacity: .4;
    /* height: 1px; */
}
.child .container-fluid:nth-last-child(1) .heading-title.clr b {
    background-color: #d2a63b;
}
.child .container-fluid:nth-last-child(1) .heading-title-main {
    color: #d2a63b;
}
.child .container-fluid:nth-last-child(2) .heading-title.clr b {
    background-color: #1866a8;
}
.child .container-fluid:nth-last-child(2) .heading-title-main {
    color: #1866a8;
}
.child .container-fluid:nth-last-child(3) .heading-title.clr b {
    background-color: #10ae97;
}
.child .container-fluid:nth-last-child(3) .heading-title-main {
    color: #10ae97;
}
.shop-bottom-area.hv-badge .badge {
    --f: 10px;
    --r: 10px;
    --t: 10px;
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 4px 10px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(84.4% - var(--f)/2));
    background: #ca9618;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    padding-bottom: 14px;
    color: #fff;
    font-size: 14px;
    z-index: 1;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 0;
  }
  .shop-bottom-area.hv-badge .product-img, .shop-bottom-area.hv-badge .product-img {
    overflow: inherit;
}
.lg-logo {
    height: auto;
    width: 200px;
}
.auth-logo {
    height: auto;
    width: 100px
}
.banner-info-wrapper .banner-info {
    position: relative;
    overflow: hidden;
}
.banner-info-wrapper .banner-info a>img {
    max-width: 100%;
    width: 100%;
    height: auto;
}
.banner-info-wrapper .banner-info .overlay-shape {
    opacity: .3;
    --lw-gradient-from: transparent;
    --lw-gradient-stops: var(--lw-gradient-from),var(--lw-gradient-to,transparent);
    --lw-gradient-to: #fff;
    background-image: linear-gradient(to right,var(--lw-gradient-stops));
    transform: translate(0,0) rotate(0) skewX(-12deg) skewY(0) scaleX(1) scaleY(1);
    -webkit-transform: translate(0,0) rotate(0) skewX(-12deg) skewY(0) scaleX(1) scaleY(1);
    -moz-transform: translate(0,0) rotate(0) skewX(-12deg) skewY(0) scaleX(1) scaleY(1);
    -ms-transform: translate(0,0) rotate(0) skewX(-12deg) skewY(0) scaleX(1) scaleY(1);
    -o-transform: translate(0,0) rotate(0) skewX(-12deg) skewY(0) scaleX(1) scaleY(1);
    width: 50%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
}
.banner-info-wrapper .banner-info:hover .overlay-shape {
    opacity: .7;
}
/* .banner-info-wrapper .banner-info .content {
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 10%;
    color: #fff;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    width: 40%;
    line-height: 1.4;
    font-weight: 600;
} */

.faq-accordion > .card > .card-header {
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    font-size: 17px;
    color: #000;
    line-height: 30px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-between;
    }
.faq-accordion > .card .card-body {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
}
.faq-accordion > .card:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 20px;
}
.faq-accordion > .card > .card-header:after {
    background-image:url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4'/></svg>")
}
.faq-accordion > .card > .card-header:focus:after {
    background-image:url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'><path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8'/></svg>")
}
.faq-accordion > .card > .card-header:after {
    background-repeat: no-repeat;
    background-size: 1.25rem;
    content: "";
    flex-shrink: 0;
    height: 1.25rem;
    /*margin-left: auto;
    */width: 1.25rem;
    display: block;
    float: right;
    transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    margin-top: 5px;
}
.faq-accordion > .card > .card-header:hover:after {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
.main-heading {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    text-align: center;
    z-index: 2;
    position: relative;
}
.main-heading h2 {
    font-size: 40px;
}
.main-heading.py {
    padding-top: 70px;
    padding-bottom: 40px;
}
.main-heading:before {
    position: absolute;
    background-color: #070508;
    height: 3px;
    width: 70px;
    content: "";
    left: 0;
    right: 0;
    bottom: 30px;
    margin: 0 auto;
}
.para p {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
}
.sell-form-info-wrapper .para p span {
    font-weight: 600;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.para p {
    margin-bottom: 18px;
}
.sell-form-info-wrapper .list ul {
    list-style-type: disclosure-closed;
    padding-left: 20px;
}
.sell-form-info-wrapper .list ul li span,
.sell-form-info-wrapper .list ul li::marker {
    font-weight: 600;
    font-size: 18px;
    color: #000;
}
.sell-form-info-wrapper {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
    padding-top: 50px;
}
.sell-form-info-wrapper .list ul li {
    margin-bottom: 15px;
}
.sub-heading {
    padding-bottom: 15px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}
.sell-form-info-wrapper .list-wrapper {
    background-color: #f7f7f7;
    padding-top: 50px;
    padding-bottom: 40px;
    margin-top: 50px;
}
.sell-form-info-wrapper .list-wrapper .sub-heading h3 {
    /* font-weight: 500; */
}
.sell-form-info-wrapper .para p span {
    color: #000;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);

}
.sell-form-info-wrapper .form-wrapper {
    position: relative;
    padding-bottom: 70px;
    background-image: url("/assets/img/bg/get-a-quote.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.sell-form-info-wrapper .form-wrapper:before {
    content: ' ';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
}
.sell-form-info-wrapper .form-control:focus,
.sell-form-info-wrapper input:focus {
    box-shadow: none;
    border-color: #343538;
}
.sell-form-info-wrapper .form-control,
.sell-form-info-wrapper input {
    border-width: 1px;
    border-color: #ced4da;
}
.sell-form-info-wrapper input,
.sell-form-info-wrapper .form-control {
    background-color: transparent;
}
.sell-form-info-wrapper .form-control {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    margin-bottom: 0 !important;
}
.form-wrapper {
    padding-top: 80px;
}
.form-wrapper .form-inner {
    background-color: #f7f7f7;
    padding-bottom: 60px;
    width: 60%;
    margin: 0 auto;
    border: 1px solid #d3d3d3;
    position: relative;
}
.about-us-wrapper {
    padding-top: 40px;
    padding-bottom: 60px;
}
.about-us-wrapper .framework-img img {
    width: 100%;
    max-width: 100%;
    height: auto;
}
.about-us-wrapper .inner {
    /* padding-top: 40px; */
    padding-bottom: 40px;
}
.popular-brands .heading-title-main {
    text-shadow: none;
}
.contact-form .contact-form-style .conatct-tel input {
    margin-bottom: 0;
}
.conatct-tel {
    /* margin-bottom: 30px; */
}
@media (min-width: 992px) {
    .slider-area.top {
        margin-top: 143px;
    }
    .all-product-banner-img,
    .all-product-banner-img img {
        height: 376px;
    }
    .gallery-container .vertical {
        height: 550px;
        margin-right: 10px;
    }
    .product-large-image-wrapper .product-image img{
        height: 550px;
    }
    .box-head-title .authentic {
        margin-bottom: 20px;
    }
}
@media (max-width: 575.98px) {
    .all-product-banner-img,
    .all-product-banner-img img {
        height: 216px;
    }
    .footer-area .top {
        padding: 20px 0 20px;
        font-size: 18px;
    }
    .footer-widget {
        margin-bottom: 40px;
    }
    .footer-widget .footer-widget-title {
        margin-bottom: 20px;
    }
    .watch-info-container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .heading-title-main {
        font-size: 19px;
    }
    .popular-brands {
        padding: 20px 0 60px;
    }
    .heading-title.space {
        margin: 20px 0 10px;
    }
    .product-area.hm5-section-padding div[class^="col-"] {
        padding-left: 6px;
        padding-right: 6px;
    }
    .main-heading.py {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .about-us-wrapper .inner {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .main-heading h2 {
        font-size: 30px;
    }
    .sub-heading h2 {
        font-size: 20px;
    }
    .para p {
        font-size: 14px;
        line-height: 1.7;
    }
    .sub-heading {
        padding-bottom: 0;
    }
}
@media (max-width: 991.98px) {
    .cat-head-list a.set-home-dropdown-button:nth-last-child(1), .cat-head-list a.set-home-dropdown-button:nth-last-child(2) {
        display: none;
    }
    .gallery-container .vertical {
        /* height: 550px; */
        margin-top: 10px;
    }
    .product-large-image-wrapper .product-image img {
        height: 320px;
    }
    .product-details-content {
        margin-top: 20px;
    }
}
@media (max-width: 425px) {
    .cat-head-list a.set-home-dropdown-button {
        padding: 4px 8px;
        font-size: 12px;
    }
}
@media (max-width: 480px) {
    .lg-logo {
        width: 120px;
    }
    .all-product-banner-img,
    .all-product-banner-img img {
        height: 156px;
    }
    .all-product-heading-wrap .heading {
        font-size: 34px;
    }
    /* .shop-area {
        padding-top: 30px;
    } */
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .all-product-banner-img, .all-product-banner-img img {
        height: 216px;
    }
}
@media (min-width: 1318px) {
    .all-product-banner-img {
        height: 436px;
        background-size: 100%;
    }
    .all-product-banner-img img {
        height: 436px;
    }
}
@media (min-width: 1536px) {
    .all-product-banner-img {
        height: 636px;
    }
    .all-product-banner-img img {
        height: 636px;
    }
}
.product-price span{
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #bf902b !important;
}
.price-label strong{
    color: #fff !important;
    font-weight: 500;
}
.price-label{
    background-color: #bf902b;
    border: 1px solid #bf902b;
}
.heading-title-main{
    color: #242424 !important;  
    font-size: 2.875rem;
    font-weight: 400;
     
}
.heading-title.space{
    text-align: center;
    display: block; 
}
.product-container p{
    padding: 0% 25% 0% 25%;
    text-align: center;
    color: #868686;
    font-size: 13px;
    margin-bottom: 0px;
}
.para-text{
    margin-bottom: 80px;
}
.recent-add{
    background-color: #F8F7F6;
}
.product-price-btn{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.product-information-box{
    padding: 15px;
}
.product-price-btn button{
    font-size: 14px !important;
}
.country-dropdown{
    font-size: 17px;
    line-height: 75px;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    cursor: pointer;
}
.popular-brands .img-thumbnail{
    background-color: transparent !important;
}
a.zoom {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    bottom: 0;
    text-align: center;
    opacity: 0;
    border: 1px solid #bf902b;
    border-radius: 100%;
    color: #000;
    display: inline-block;
    height: 44px;
    width: 44px;
    line-height: 42px;
    text-align: center;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -webkit-transition: all 1s ease 0s;
    -moz-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
    z-index: 3;    
}
a.zoom i{
    font-weight: 700;
    font-size: 16px;
}
.product-img:hover a.zoom {
    opacity: 1;
    top: 50%;
    background-color: #bf902b;   
}
.product-img::after{
    background-color: rgba(0,0,0,.6);
}
.about-banner{
    background-image: url("/assets/img/bg/about-banner-1.jpg");
    position: relative;
    min-height: 0em;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.about-banner h2{
    color: #fff;
}
.about-banner .main-heading:before{
    background-color: #fff;
}
.product-banner-img{
    position: relative;
    min-height: 0em;
    display: -webkit-flex;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.product-banner-img h2{
    color: #fff;
}
.product-banner-img .main-heading::before{
    background-color: #fff;
}
.product-details .container-fluid{
    padding-left: 15px;
    padding-right: 15px;
}
.productdetailspage-banner img{
    width: 100%;
}
.product-details-info{
    padding-top: 50px !important;
}
.related-product-area h2{
    color: #242424 !important;
    font-size: 2.875rem;
    font-weight: 400;
    font-family: 'Crimson Text', 'serif';
}
.related-product-area .section-title h2::before,
.related-product-area .section-title h2::after{
    display: none;
}
.related-product .product-wrap .product-content{
    padding: 15px;
}
.product-details-content .heading-title-main{
        font-size: 1.875rem;
}
/* .contact-form-style .addproduct-error-msg {
    position: relative;
} */
.get-a-quote .form-group{
    margin-bottom: 2rem;
}
.offcanvas-mobile-menu .lang-curr-style select{
    color: #111;
    font-weight: 600;
    padding: 10px 0;
    display: block;
}
.sell-consign-banner {
    background-image: url(/assets/img/bg/sell-banner-new.png);
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sell-consign-banner h2{
    color: #fff;
}
.sell-consign-banner .main-heading:before {
    background-color: #fff;
}
.faq-wrapper{
    padding-top: 50px;
}
.faq-banner {
    background-image: url(/assets/img/bg/get-a-quote.jpg);
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.faq-banner h2{
    color: #fff;
}
.faq-banner .main-heading:before {
    background-color: #fff;
}
.contact-banner {
    background-image: url(/assets/img/bg/contact-us.png);
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-banner h2{
    color: #fff;
}
.contact-banner .main-heading:before {
    background-color: #fff;
}
.productdetailspage-banner{
    position: relative;
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(/assets/img/bg/all-product-banner.jpg);
}
.productdetailspage-banner .heading-title-main{
    color: #fff !important;
}
.productdetailspage-banner .main-heading:before{
    background-color: #fff;
}
.product-banner-img .main-heading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}
.image-overlay:before {
    background-color: rgba(0, 0, 0, 0.45);
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}
.recent-add img{
    height: 400px !important;
}
.phone-error{
    bottom: 4px;
}
.phone-no-error{
    bottom: 0px;
}